import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="blutlabor" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Blut-Labor`}</h1>
      <h3 {...{
        "id": "blut-labor"
      }}>{`Blut-Labor`}</h3>
      <p>{`Moderne klinische Blutlabor-Diagnostik gehört selbstverständlich ins Programm einer seriösen Naturheilpraxis.`}</p>
      <p>{`Ohne die Einschränkungen/Sparmaßnahmen der Krankenkassen können wir sinnvolle und aussagefähige Profile erstellen. Dafür arbeiten wir mit renommierten medizinischen Labors zusammen, deren zuverlässige Ergebnisse uns häufig auch entsprechende therapeutische Ansätze aufzeigen.`}</p>
      <p>{`Neben einer umfangreichen Basis-Blut-Untersuchung bieten sich bei entsprechenden Fragestellungen auch erweiterte Module an – so schaffen z.B. entsprechende Vorsorge-Module Klarheit bei:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Leistungsschwäche`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Mangelerkrankungen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Durchblutungsstörungen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Stoffwechselstörungen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Alzheimer-Demenz
Neben diesen klinischen Labor-Untersuchungen bieten wir diverse blutdiagnostische Verfahren an, die Aussagen zu vorklinischen Veränderungen im Körper zulassen:`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Blutkristallanalyse`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`HLB-Bradford-Bluttest`}</p>
        </li>
      </ul>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      